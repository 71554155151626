<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="学年" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入学年" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<el-button type="primary" icon="el-icon-document-copy" size="mini" @click="handleHp">合并</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="学年" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.year }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="学期" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.tag }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="50" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">完成</el-tag>
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="110">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope" >
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
					  <el-button type="success" icon="el-icon-view" size="mini" circle @click="downloadView(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="下载" placement="top">
					  <el-button type="primary" icon="el-icon-download" size="mini" circle @click="downloadExcel(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="scope.row.status == 1">
					  <el-button type="warning" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="scope.row.status == 1">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		    <el-form-item label="学年" prop="start_year">
			   <el-date-picker
				v-model="form.start_year"
				type="year"
				value-format="yyyy"
				style="width: 150px;"
				placeholder="请选择学年">
			   </el-date-picker>
			   <span class="mx-1">至</span>
			   <el-date-picker
				v-model="form.end_year"
				type="year"
				value-format="yyyy"
				style="width: 150px;"
				placeholder="请选择学年">
			   </el-date-picker>
		    </el-form-item>
			<el-form-item label="学期" prop="tag">
			  <el-select v-model="form.tag" placeholder="请选择学期" style="width: 250px;">
				  <el-option :value="item.name" :label="item.name" v-for="(item,index) in tags" :key="index"/>
			  </el-select>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
		<!-- 合并-->
		<el-dialog :visible.sync="openHp" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="hpForm" :model="hpForm" :rules="hpRules" label-width="80px" size="small">
			<el-form-item label="学期" prop="semester_id">
			  <el-select v-model="hpForm.semester_id" placeholder="请选择学期" style="width: 250px;">
				  <el-option v-for="(item,index) in semesters" :key="index"
				  	:label="item.year+'-'+item.tag" 
				  	:value="item.id">
				  </el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="学期" prop="semester_id2">
			  <el-select v-model="hpForm.semester_id2" placeholder="请选择学期" style="width: 250px;">
				  <el-option v-for="(item,index) in semesters" :key="index"
				  	:label="item.year+'-'+item.tag" 
				  	:value="item.id">
				  </el-option>
			  </el-select>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="openHp = false">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleHpSubmit">合并导出</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import dayjs from 'dayjs'
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'semester',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					start_year:'',
					end_year:'',
					tag:'',
				},
				rules:{
					start_year: [
					  { required: true, message: '请选择起始学年', trigger: 'blur' }
					],
					end_year: [
					  { required: true, message: '请选择结束学年', trigger: ['blur', 'change'] }
					],
					tag: [
					  { required: true, message: '请选择学期', trigger: ['blur', 'change'] }
					],
				},
				tags:[
					{id:1,name:'第一学期'},
					{id:2,name:'第二学期'},
				],
				openHp:false,
				hpRules:{
					semester_id: [
					  { required: true, message: '请选择学期', trigger: ['blur', 'change'] }
					],
					semester_id2: [
					  { required: true, message: '请选择学期', trigger: ['blur', 'change'] }
					],
				},
				hpForm:{
					semester_id:'',
					semester_id2:'',
				},
				semesters:[],
			}
		},
		mounted() {
			this.getSemesters()
		},
		methods:{
			handleHp(){
				this.title = "数据合并"
				this.openHp = true
			},
			handleHpSubmit(){
				this.$refs.hpForm.validate(valid => {
				  if (valid) {
					  // this.axios.get('/manage/semester/hbDownload',{
					  // 	params:{
					  // 		semester_id: this.hpForm.semester_id,
					  // 		semester_id2: this.hpForm.semester_id2,
					  // 	}
					  // }).then(res=>{
					  // 	console.log(res)
					  // })
					  this.axios.get('/manage/semester/hbDownload',{
					  	responseType: 'arraybuffer',
					  	params:{
					  		semester_id: this.hpForm.semester_id,
					  		semester_id2: this.hpForm.semester_id2,
					  	}
					  }).then(res=>{
					  	console.log(res)
					  	  let dayjsTime = dayjs(`${new Date()}`).format('YYYY-MM-DD')
					  	  const aLink = document.createElement("a");
					  	  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  	  aLink.href = URL.createObjectURL(blob);
					  	  aLink.download = '积分合并_'+dayjsTime+'.xlsx';
					  	  aLink.click();
					  	  document.body.appendChild(aLink);
					  })
				  }
				})
			},
			getSemesters(){
				this.axios.get('/manage/semester/all').then(res=>{
					if(res.status){
						this.semesters = res.data
					}
				})
			},
			downloadView(row){
				this.$router.push({
				  path:"/semester_list",
				  query: {
					id: row.id,
				  }
				})
			},
			handleSubmit() {
			  this.$refs.form.validate(valid => {
			    if (valid) {
			      if (this.form.sort) {
			        this.form.sort = parseInt(this.form.sort)
			      }
				  if(this.form.end_year == '' || this.form.end_year == undefined){
					  this.$message.error('请选择结束学年')
					  return false
				  }
			      if (this.form.id === undefined) {
			        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
			          if (res.status) {
			            this.$message.success('添加成功')
			            this.open = false
			            this.getList()
			          } else {
			            this.$message.error(res.msg)
			          }
			        })
			      } else {
			        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
			          if (res.status) {
			            this.$message.success('修改成功')
			            this.open = false
			            this.getList()
			          } else {
			            this.$message.error(res.msg)
			          }
			        })
			      }
			    }
			  })
			},
			downloadExcel(row){
				this.axios.get('/manage/semester/downloadExcel',{
					responseType: 'arraybuffer',
					params:{
						id: row.id,
					}
				}).then(res=>{
					console.log(res)
					  let dayjsTime = dayjs(`${new Date()}`).format('YYYY-MM-DD')
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '积分考核汇总_'+dayjsTime+'.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
		}
	}
</script>

<style>
</style>